import React from 'react';
import { Logger }           from 'aws-amplify';
import ChatBotify from "react-chatbotify";
const logger = new Logger('Chatbot');

export default function Chatbot(props) {
    
    const themes = [
		{id: "minimal_midnight", version: "0.1.0"}
	];
    
    return (<ChatBotify themes={themes} />);
    
}