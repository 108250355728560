export const constants = {
    app: "ORIGINS",
    title: "Origins",
    tagline: "The NextCloud.aero portal for PAC employees.",
    release_month: "November 2019",
    theme: "origins",
    loading: {
        background_color: "#16456d"
    },
    report_list: "reports.origins.json",
    //nextcloud_menu: false,
    faq_url: "https://confluence.cloud.panasonic.aero/wiki/spaces/AC/pages/84084288/Origins+-+FAQ",
    feedback_url: "/feedback",
    overwrite_roles_by_icao: false,
    profile_page: true,
    log_page: true,
    assets_path: "https://origins-assets.nextcloud.aero/reportconfig-images/origins/",
    cloudbar: false,
    report_acl: false
};
  